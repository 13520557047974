import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import forgotPasswordReducer from '@core/redux//forgotPasswordSlice'
import appReducer from '@core/redux/appSlice'
import authReducer from '@core/redux/authSlice'
import loginReducer from '@core/redux/loginSlice'
import mfaReducer from '@core/redux/mfaSlice'
import organizationReducer from '@core/redux/organizationSlice'
import snackbarReducer from '@core/redux/snackbarSlice'
import verifyEmailReducer from '@core/redux/verifyEmailSlice'

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  PreAuthApp: () => null,
  auth: authReducer,
  application: appReducer,
  login: loginReducer,
  mfaDialog: mfaReducer,
  verifyEmail: verifyEmailReducer,
  forgotPassword: forgotPasswordReducer,
  snackbar: snackbarReducer,
  organization: organizationReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const store: any = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({ serializableCheck: false })
})

// TODO: uncomment when enable persist and react-state-sync
// initMessageListener(store)
// export const persistor = persistStore(store)
// export const persistor = null

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store

import { IOrganization } from "@extensiv/sdk/hub"
import { onErrorResponse } from "@extensiv-app/utility"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash"

import { apiGetCurrentOrg } from "@core/services/auth.service"
import { NetworkResponse } from "@core/util/Api"

export const getOrganizationDetails = createAsyncThunk<
  NetworkResponse<IOrganization>,
  void
>(
  'organizations/details',
  async (_, { rejectWithValue }) => {
    const response = await apiGetCurrentOrg()

    if (response.error) {
      onErrorResponse(response.error)
      return rejectWithValue(response.error)
    }

    return response
  }
)

export interface OrganizationState {
  organizationDetails?: IOrganization | null | undefined,
  isFetchingOrganization: boolean
}

const initialState: OrganizationState = {
  organizationDetails: null,
  isFetchingOrganization: false,
}

const organizationSlice = createSlice({
  name: 'organizationReducer',
  initialState: cloneDeep(initialState),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrganizationDetails.pending, state => {
        state.isFetchingOrganization = true
      })
      .addCase(getOrganizationDetails.fulfilled, (state, { payload }: PayloadAction<NetworkResponse<IOrganization>>) => {
        state.organizationDetails = payload?.data
        state.isFetchingOrganization = false
      })
      .addCase(getOrganizationDetails.rejected, state => {
        state.isFetchingOrganization = false
      })
  }
})

export const {} = organizationSlice.actions

export default organizationSlice.reducer

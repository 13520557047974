import { useMemo, useState, useRef } from 'react'

import { IAppIdType } from '@extensiv/sdk/hub'
import { CoreLink, Text, CoreTypography, AppBadge } from '@extensiv/shared-reactcomponents'
import { getRespectiveUrlForAppInstance, IHubApplication } from '@extensiv-app/utility'
import { faCaretDown, faArrowTurnDownRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, MenuList, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import moment from 'moment'

import { useAppSelector } from '@core/redux/store'
import { appKeyToPackageMap, appNameToHumanReadable } from '@core/util/commonFunctions'

import useStyles from './styles'
import { useHistory } from 'react-router'

interface IProps {
  availableProducts: any[]
  onAppSelect(): any
  productsWithInstances?: Record<string, IAppIdType[]>
}


export default ({
  availableProducts,
  onAppSelect,
  productsWithInstances = {},
}: IProps) => {
  const { classes } = useStyles()
  const updatedApps = useAppSelector(state => state.application.recentlyUpdatedApps)
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const [ selectedAppUpdate, setSelectedAppUpdate ] = useState({
    app: '',
    updateTime: '',
    version: ''
  })
  const appNotifRefs = useRef<{ [k: string]: any }>({})
  const [ panel, setPanel ] = useState<string | boolean>(false)
  const history = useHistory()

  const recentlyUpdatedApps = useMemo(
    () => JSON.parse(localStorage.getItem('appUpdates') || '{}'),
    [ updatedApps ]
  )

  const getAppUpdateByAppKey = (appKey: string) => {
    const update = recentlyUpdatedApps[appKeyToPackageMap[appKey]]

    // Ignore updates older than 24 hours
    if (!update || (update && moment(update.updateTime).isBefore(moment().subtract(24, 'hours')))) return null

    return update
  }

  appNotifRefs.current = useMemo(
    () => Object.keys(recentlyUpdatedApps).reduce((acc, app) => {
      const update = getAppUpdateByAppKey(app)

      if (!update) return acc

      return {
        ...acc,
        [update.app]: null
      }
    },
      {}),
    [ updatedApps ]
  )

  const onPopoverTrigger = (appPackageName: string | null) => (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    setAnchorEl(appPackageName ? appNotifRefs.current[appPackageName] : null)

    const appUpdate = recentlyUpdatedApps[appPackageName || '']
    setSelectedAppUpdate(appUpdate ? appUpdate : { app: '', updateTime: '', version: '' })
  }

  const handleAccordionChange = (app: IHubApplication) => {
    return (event: React.SyntheticEvent, accordionState: boolean) => {
      const instances = productsWithInstances[app?.key] || []
      if (app?.supportsMultiInstance && instances && instances.length > 1) {
        setPanel(accordionState ? app?.key : false)
        return;
      }

      if(instances && (instances.length === 0 || instances.length === 1)) {
        const redirectUrl = getRespectiveUrlForAppInstance(app, instances[0])
        if (redirectUrl) {
          history.push(redirectUrl)
          onAppSelect()
        }
      }
    }
  }

  const onAppInstanceClick = (app: IHubApplication, product: IAppIdType) => {
    const redirectUrl = getRespectiveUrlForAppInstance(app, product)
    history.push(redirectUrl)
    onAppSelect()
  }

  return (
    <>
      <MenuList className={ classes.AppGridContainer }>
        {availableProducts.map(product => (
          <div
            key={ product.title }
          >
            <Accordion
              disableGutters
              elevation={ 0 }
              square
              expanded={ panel === product.key }
              onChange={ handleAccordionChange(product) }
              className={ classes.AppMenuItem }
            >
              <AccordionSummary
                className={ classes.AccordionSummaryWrapper }
                aria-controls={ `${product.key}-content` }
                id={ `${product.key}-header` }
                {
                  ...productsWithInstances[product?.key]?.length > 1 ? {
                    expandIcon: <FontAwesomeIcon className={ classes.SummaryStateIndicatorIcon } icon={ faCaretDown } />
                  } : {}
                }
              >
                <div
                  className={ classes.SummaryContentWrapper }
                >
                  <div className={ classes.IconAndTitleWrapper }>
                    <product.logo style={{ fill: product?.themeColor }} fillColor={ product?.themeColor }
                      className={ classes.AppIcon } />
                    <CoreTypography variant='bodyMd'>
                      {product?.title}
                    </CoreTypography>
                  </div>
                  <div>
                    {product?.isBeta && (
                      <AppBadge color='grey'>BETA</AppBadge>
                    )}
                    {product?.isNew && (
                      <AppBadge color='magenta'>NEW</AppBadge>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={ classes.AccordionDetailsWrapper }>
                {product?.supportsMultiInstance && productsWithInstances[product?.key]?.length > 1 && productsWithInstances[product.key].map((appInstance: IAppIdType, index) => (
                  <div
                    className={ classes.AppInstanceIconAndTitleWrapper }
                    key={ index }
                    onClick={ () => { onAppInstanceClick(product, appInstance) } }
                  >
                    <div
                      className={ classes.InstanceIconWrapper }
                    >
                      <FontAwesomeIcon icon={ faArrowTurnDownRight } color="#415364" />
                    </div>
                    <span className={ classes.TitleWrapper }>{appInstance?.name}</span>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </MenuList>
      <Popover
        id='updateNotificationPopover'
        open={ !!anchorEl }
        anchorEl={ anchorEl }
        onClose={ () => setAnchorEl(null) }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={ classes.UpdateNotificationPopover }
        PaperProps={{ onMouseEnter: onPopoverTrigger(selectedAppUpdate.app), onMouseLeave: onPopoverTrigger(null) }}
      >
        <Text small white overflowEllipsis={ false }>
          {appNameToHumanReadable[selectedAppUpdate.app]} was
          updated on {moment(selectedAppUpdate.updateTime).format('MM/DD/YYYY')}.
          Click <a href='/'>HERE</a> to see update information.
        </Text>
      </Popover>
    </>
  )
}

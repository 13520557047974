import { makeStyles } from 'tss-react/mui'

import CssVariables from '@core/assets/styles/css-variables'

export default makeStyles()((theme) => ({
  AppGridContainer: {
    maxHeight: '400px',
    overflow: 'auto',
    padding: 0,
    '& > a': {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      color: CssVariables.black
    },
    '& div:last-child': {
      '.MuiPaper-root .MuiAccordionSummary-root': {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    }
  },
  AppMenuItem: {
    width: '100%',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center'
    },
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    '&.MuiPaper-root': {
      borderTopLeftRadius: '0px !important',
      borderTopRightRadius: '0px !important',
    },
  },
  AccordionSummaryWrapper: {
    background: '#F5F5F5',
    padding: '10px 16px',
    margin: '0px',
    height: '40px',
    minHeight: '20px',
    '& .MuiAccordionSummary-content': {
      margin: '0px',
    }
  },
  SummaryContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  IconAndTitleWrapper: {
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
  },
  SummaryStateIndicatorIcon: {
    height: '20px',
    width: '20px',
    fontSize: '16px',
    padding: '5px',
    color: '#222',
  },
  AccordionDetailsWrapper: {
    minHeight: '40px',
    padding: '0px 0px 0px 30px',
    flexShrink: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    background: '#FFF',
    cursor: 'pointer',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  AppInstanceIconAndTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
  },
  InstanceIconWrapper: {
    width: '40px',
    height: '100%',
    padding: '10px',
    borderRadius: '4px',
    background: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 900,
  },
  TitleWrapper: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '166%',
    letterSpacing: '0.4px',
  },
  AppIcon: {
    height: '16px',
    width: 'auto',
    marginRight: '12px'
  },
  LinkContentContainer: {
    justifyContent: 'center',
    alignSelf: 'baseline'
  },
  AppIconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  AppIconShineOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    transform: 'skew(225deg)',
    width: '75px',
    height: '75px',
    position: 'absolute',
    left: '37px'
  },
  AppNameContainer: {
    marginTop: '12px',
    textAlign: 'center'
  },
  AppItemLink: {
    textDecoration: 'none !important',
    color: '#333',
    textAlign: 'center',
    width: '100%'
  },
  AppUpdateNotificationIcon: {
    backgroundColor: CssVariables.accent,
    borderRadius: 100,
    height: 10,
    width: 10,
    position: 'absolute',
    right: 10,
    top: 10
  },
  UpdateNotificationPopover: {
    '& .MuiPopover-paper': {
      background: '#000000CC',
      padding: '5px 10px',
      maxWidth: 260,
      marginTop: 5
    }
  }
}))
